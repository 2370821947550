.FoxIcon {
  font-family: 'FoxIcon', sans-serif;
  font-size: 28px;
  pointer-events: none;

  &.list-icon{
    margin-right: 16px;
  }
  &.hasAction:hover {
    cursor: pointer;
  }

  .MuiSvgIcon-root {
    font-size: 28px;
    display: inline-block;
  }

  &.FoxIconUnknown {
    @extend %IconUnknown
  }

  &.alive,
  &.ok {
    color: var(--green600);
  }

  &.disconnected,
  &.warning {
    color: var(--orangy600);
  }

  &.dead,
  &.danger {
    color: var(--red600);
  }

  &.unknown {
    color: var(--grey600);
  }

  &.not-ready {
    color: var(--blue600);
  }
}

.MuiButton-startIcon.MuiButton-iconSizeMedium {
  margin-left: 0;
  margin-right: 7px;

  .FoxIcon {
    font-family: 'FoxIcon', sans-serif;
    font-size: 22px;
    width: 20px;
    height: 20px;
    line-height: 20px;

    &.hasAction:hover {
      cursor: pointer;
    }

    .MuiSvgIcon-root {
      font-size: 20px;
      display: inline-block;
      transform: translateY(0);
      -moz-transform: translate(0);
    }
  }
}

%FoxIconBoolean {
  line-height: 14px;
  margin-right: 5px;
  padding-bottom: 4px;
  transform: scale(1.4);
}

%IconUnknown {
  color: var(--grey100);
}
