.upload__image-wrapper {
  .upload__image-action-label {
    color: var(--grey600);
  }

  .upload__image-button {
    margin: 1em 1em 1em 0;
    height: 48px;
  }

  .upload__image-error {
    color: var(--red600);
  }

  .image-item {
    margin: 1em 1em 1em 0;

    img {
      max-width: 80px;
      height: 80px;
      border-radius: 10px;
      border-width: 1px;
      border-style: solid;
      object-fit: cover;
    }
  }

  .image-item__btn-wrapper {
    color: white;
    background-color: var(--red600);
    left: -31px;
    top: 3px;
    padding: 1px;
    height: 28px;
    width: 28px;
    &:hover {
      background-color: var(--red800);
    }
  }

  div button.image-add__btn-wrapper {
    margin: 16px 0;
    width: 102px;
    height: 48px;
    color: var(--blue600);
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
  }
}
