@font-face {
  font-family: 'SuisseIntl';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: local(SuisseIntl),
    url('../resource/fonts/SuisseIntl-Regular.otf') format("opentype");
}

@font-face {
  font-family: 'SuisseIntl';
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: local(SuisseIntl),
    url('../resource/fonts/SuisseIntl-SemiBold.otf') format("opentype");
}

@font-face {
  font-family: 'FoxIcon';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: local(FoxIcon),
    url('../resource/fonts/Foxicon-Regular.otf') format('opentype');
}

html {
  font-size: 12px;
  height: 100%;
}

body {
  margin: 0;
  padding-right: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'SuisseIntl', "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: normal;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
