.app {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr min-content;
  margin: 0 auto;
  max-width: 640px;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 16px 16px 0;

  > div:first-of-type {
    .FoxBanner, p.installation_images_upload_description {
      margin-bottom: 32px;
      line-height: 1.63;
    }
  
    .upload__image-wrapper {
      &:not(:first-of-type) {
        padding: 16px 0;
      }

      .upload__image-action-label, .upload__image-error {
        padding: 0 8px;
      }

      button.upload__image-button {
        margin: 8px 0
      }
    }
  }

  > div:last-of-type {
    position: sticky;
    background-color: white;
    bottom: 0;
    padding: 16px 0;

    .FoxBanner {
      margin-bottom: 32px;
    }

    button {
      height: 48px;
    }
  }
}
