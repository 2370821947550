@use 'shared/src/styles/constants' as *;

.fox-loader {
  height: 100%;

  .MuiBackdrop-root {
    position: static;
    height: 100%;
  }

  .full-sized {
    height: $vh100;
  }
}
