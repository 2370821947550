.FoxBanner {
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  max-width: 100%;
  box-shadow: 0 6px 13px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  font-size: 16px;
  line-height: 1.63;
  background-color: var(--grey100);
  color: var(--grey800);
  border: 1px solid var(--grey400);

  &.success {
    background-color: var(--green200);
    border: 1px solid var(--green800);
  }

  &.alert {
    background-color: var(--orangy200);
    border: 1px solid var(--orangy800);
  }

  &.warning {
    background-color: var(--red200);
    border: 1px solid var(--red800);
  }

  .content {
    margin-left: 16px;
  }
}
